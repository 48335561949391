import React from 'react';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout activeLink="Contact Us">
    <section className="page-section cta">
      <div className="container">
        <div>
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
            <h2 className="section-heading mb-5">
                <span className="section-heading-upper"></span>
                <span className="section-heading-lower">Enquiry Form</span>
            </h2>

          <form method="post" action="https://www.designersweettreats.net/contact-form" target="_blank" rel="noopener noreferrer">
            <div>
              
              <div className="col-12">
              <div><label htmlFor="name"><i>Event Date</i></label></div>
              <input
                  className="date"
                  type="date"
                  name="date"
                  id="date"
                  defaultValue="DD/MM/YYYY"
                  placeholder="15/11/2020"
                />
              </div>

              <div className="col-12">
                <div><label htmlFor="name"><i>Choose a Flavour</i></label></div>
                  <select id="flavours" name="flavours">
                    <option value="Vanilla">Vanilla</option>
                    <option value="Lemon">Lemon</option>
                    <option value="Orange">Orange</option>
                    <option value="Coffee">Coffee</option>
                    <option value="Rainbow">Rainbow</option>
                    <option value="Coconut">Coconut</option>
                    <option value="Banana">Banana</option>
                    <option value="Carrot">Carrot</option>
                    <option value="Red velvet">Red velvet</option>
                    <option value="Chocolate Sponge">Chocolate Sponge</option>
                    <option value="Dark Chocolate Mud">Dark Chocolate Mud</option>
                    <option value="White Chocolate Mud">White Chocolate Mud</option>
                    {/* <option value="Vanilla sponge cake & vanilla buttercream">Vanilla sponge cake & vanilla buttercream</option>
                    <option value="Lemon sponge cake & lemon curd buttercream">Lemon sponge cake & lemon curd buttercream</option>
                    <option value="Orange sponge cake & homemade orange source">Orange sponge cake & homemade orange source</option>
                    <option value="Coffee sponge cake & coffee buttercream">Coffee sponge cake & coffee buttercream</option>
                    <option value="Chocolate sponge & chocolate ganache">Chocolate sponge & chocolate ganache</option>
                    <option value="Rainbow cake & vanilla buttercream">Rainbow cake & vanilla buttercream</option>
                    <option value="Coconut chiffon cake & homemade coconut sauce">Coconut chiffon cake & homemade coconut sauce</option>
                    <option value="Purple potato chiffon cake & homemade potato cream">Purple potato chiffon cake & homemade potato cream</option>
                    <option value="Banana cake & cream cheese">Banana cake & cream cheese</option>
                    <option value="Carrot cake & cream cheese">Carrot cake & cream cheese</option>
                    <option value="Red velvet & cream cheese">Red velvet & cream cheese</option>
                    <option value="Chocolate mud cake & chocolate ganache">Chocolate mud cake & chocolate ganache</option>
                    <option value="White chocolate mud cake & white chocolate ganache">White chocolate mud cake & white chocolate ganache</option> */}
                  </select>  
              </div>

              <div className="col-12">
              <div><label htmlFor="name"><i>Number of Servings</i></label></div>
              <select id="servings" name="servings">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="17">19</option>
                    <option value="18">20</option>
                  </select>
              </div>

              <div className="col-12">
              <div><label htmlFor="subject"><i>Cake Design</i></label></div>  
              <textarea
                  name="design"
                  id="design"
                  placeholder="Please provide details on cake design"
                  rows="6"
                ></textarea>
              </div>

              <div className="col-12">
              <div><label htmlFor="name"><i>Name</i></label></div>
                <input
                  className="text"
                  type="text"
                  name="name"
                  id="name"
                  defaultValue=""
                  placeholder="Cupcake Monster"
                /> 
              </div>

              <div className="col-12">
              <div><label htmlFor="email"><i>Email</i></label></div> 
                <input
                  className="text"
                  type="text"
                  name="email"
                  id="email"
                  defaultValue=""
                  placeholder="cupcakemonster@sesamestreet.com.au"
                />
              </div>
              
              <div className="col-12">
              <div><label htmlFor="subject"><i>Other Information</i></label></div>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Enter any other information here"
                  rows="6"
                ></textarea>
              </div>
              
              <div className="col-12">
                <div className="actions special">
                    <input className="button" type="submit" value="Send" />
                    <input className="button" type="reset" value="Reset"/>
                </div>
              </div>
            </div>
          </form>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </section>


  </Layout>
);

export default IndexPage;
